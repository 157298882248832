import { EnvironmentConstants } from "../../constants/environment";
import { NxConstants } from "../../constants/nx_constants";
import { CertResult } from "../../domain/model/cert/cert_result";
import { CertValidationResult } from "../../domain/model/cert/cert_validation_result";
import { DomainError } from "../../domain/model/common/domain_error";
import { ErrorCode } from "../../domain/model/common/error_code";
import { ServerSentError } from "../../domain/model/common/server_sent_error";
import { BaseRepository } from "./base_repository";

class CertRepository extends BaseRepository {
    async getInstallationStatus(): Promise<boolean> {
        try {
            await this.localClient.post("?op=setup");

            return true;
        } catch (_) {
            return false;
        }
    }

    getInstallUrl(isMacOs: boolean): string {
        if (isMacOs) {
            return "https://www.infotech.co.kr/iftnxweb_macos_x64_1.5.pkg";
        }

        return "https://www.infotech.co.kr/ExAdapter_Web_Setup_20221130.exe";
    }

    async getCertResult(): Promise<CertResult> {
        try {
            const response = await this.localClient.post("?op=certSelect", {
                certImageUrl: EnvironmentConstants.imageBannerUrl,
                nxKeypad: "",
            });

            const data = response.data;

            if (!data.file1 || !data.file2) {
                throw new DomainError(
                    ErrorCode.CertListWindowClosed,
                    "Cert list window closed"
                );
            }

            return {
                certName: data.cert_nm,
                registeredDate: data.pub_dt,
                expireDate: data.end_dt + " 23:59:59",
                organization: data.org_nm,
                oid: data.oid,
                serialNumber: data.sn,
                derFilePath: data.file1,
                keyFilePath: data.file2,
                certPassword: data.cert_pw,
            };
        } catch (e) {
            if (e instanceof DomainError) throw e;

            throw DomainError.unknown();
        }
    }

    async getCertValidationResult(
        certResult: CertResult
    ): Promise<CertValidationResult> {
        try {
            const response = await this.localClient.post("?op=execute", {
                orgCd: "common",
                svcCd: "getCertInfo",
                appCd: NxConstants.appCode,
                signCert: certResult.derFilePath,
                signPri: certResult.keyFilePath,
                signPw: certResult.certPassword,
            });

            const data = response.data;

            const certDerTokens = (data.DER2PEM as string).split("\n");
            const certKeyTokens = (data.KEY2PEM as string).split("\n");

            const tokenValid = (token: string) => {
                if (token.length <= 0) return false;
                if (token.includes("-----BEGIN")) return false;
                if (token.includes("-----END")) return false;

                return true;
            };

            const certDer = certDerTokens.filter(tokenValid).join("\n");
            const certKey = certKeyTokens.filter(tokenValid).join("\n");

            return {
                certDer: certDer,
                certKey: certKey,
            };
        } catch (e) {
            if (e instanceof DomainError) throw e;

            throw DomainError.unknown();
        }
    }

    async registerCert({
        businessRegistrationNumber,
        certResult,
        certValidationResult,
    }: {
        businessRegistrationNumber: string;
        certResult: CertResult;
        certValidationResult: CertValidationResult;
    }): Promise<void> {
        try {
            await this.httpClient.post("/web/edi/register", {
                cert: certValidationResult.certDer,
                certKey: certValidationResult.certKey,
                certPassword: certResult.certPassword,
                expiredAt: certResult.expireDate,
                businessNumber: businessRegistrationNumber,
            });
        } catch (e) {
            if (e instanceof ServerSentError) throw e;
            if (e instanceof DomainError) throw e;

            throw DomainError.unknown();
        }
    }
}

export const certRepository = new CertRepository();
