import { DomainError } from "../../domain/model/common/domain_error";
import { ServerSentError } from "../../domain/model/common/server_sent_error";
import { BaseRepository } from "./base_repository";

class AuthRepository extends BaseRepository {
    async isAuthorized(businessRegistrationNumber: string): Promise<void> {
        try {
            await this.httpClient.post(
                `/web/edi/check/${businessRegistrationNumber}`
            );
        } catch (e) {
            if (e instanceof ServerSentError) throw e;
            if (e instanceof DomainError) throw e;

            throw DomainError.unknown();
        }
    }
}

export const authRepository = new AuthRepository();
