import React, { useRef } from "react";
import styled from "styled-components";
import { Fonts } from "../../../theme/fonts";
import { Palette } from "../../../theme/palette";
import { SvgAssets } from "../../../theme/assets";
import { S } from "../../../theme/s";
import {
    JrcFontWeights,
    JrcTransitioner,
    jrcFadeInOutTransition,
} from "@jeekim-company/jeekim-react-core";

const CertRegisterSuccessLabelViewContainer = styled.div`
    margin-left: 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    background-color: ${Palette.othersBlueLightBg};
    border-radius: 16px;

    ${jrcFadeInOutTransition};
`;

const CertRegisterSuccessImageContainer = styled(SvgAssets.Done)`
    width: 1.5rem;
    height: 1.5rem;
    fill: ${Palette.primary100};
`;

const CertRegisterSuccessLabelContainer = styled.span`
    ${Fonts.body1}
    font-size: 1rem;
    font-weight: ${JrcFontWeights.bold};
    color: ${Palette.primary100};
`;

interface CertRegisterSuccessLabelViewProps {
    visible: boolean;
}

export const CertRegisterSuccessLabelView = ({
    visible,
}: CertRegisterSuccessLabelViewProps) => {
    const ref = useRef(null);

    return (
        <JrcTransitioner
            componentRef={ref}
            visible={visible}
            className={"cert-register-success-label"}
            child={
                <CertRegisterSuccessLabelViewContainer ref={ref}>
                    <CertRegisterSuccessImageContainer />
                    <CertRegisterSuccessLabelContainer>
                        {S.certPage.registerSuccessMessage}
                    </CertRegisterSuccessLabelContainer>
                </CertRegisterSuccessLabelViewContainer>
            }
        ></JrcTransitioner>
    );
};
