import React, { useCallback, useEffect, useState } from "react";
import { S } from "../../../theme/s";
import { useRecoilValue } from "recoil";
import { certUserInputSelector } from "../../../\bstates/cert/selectors/cert_user_input_selector";
import { CertConstants } from "../../../\bstates/cert/constants/cert_constants";
import { certController } from "../../../controllers/cert/cert_controller";
import { certRegisterSuccessSelector } from "../../../\bstates/cert/selectors/cert_register_success_selector";
import { PrimaryButton } from "../../../components/primary_button";

export const CertRegisterButtonView = () => {
    const [loading, setLoading] = useState(false);
    const userInput = useRecoilValue(certUserInputSelector);
    const registerSuccess = useRecoilValue(certRegisterSuccessSelector);

    const isEnterKey = useCallback((key: string) => {
        return key === "Enter";
    }, []);

    const isEnabled = useCallback(() => {
        return (
            userInput.length === CertConstants.userInputMaxLength &&
            !registerSuccess
        );
    }, [userInput.length, registerSuccess]);

    const onClick = useCallback(async () => {
        setLoading(true);
        await certController.onCertRegisterRequested(userInput);
        setLoading(false);
    }, [userInput]);

    useEffect(() => {
        const onKeyPressed = (event: KeyboardEvent) => {
            if (!isEnterKey(event.key)) return;
            if (!isEnabled()) return;

            onClick();
        };

        document.addEventListener("keyup", onKeyPressed, false);

        return () => document.removeEventListener("keyup", onKeyPressed, false);
    }, [isEnabled, isEnterKey, onClick]);

    return (
        <PrimaryButton
            disabled={!isEnabled()}
            loading={loading}
            onClick={onClick}
            title={S.certPage.registerButtonTitle}
        />
    );
};
