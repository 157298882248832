import React from "react";
import styled from "styled-components";
import { PngAssets } from "../../../theme/assets";
import { Palette } from "../../../theme/palette";
import { S } from "../../../theme/s";
import { JrcFontWeights, JrcSizedBox } from "@jeekim-company/jeekim-react-core";
import { Fonts } from "../../../theme/fonts";

const CertDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;

const CertDescriptionTitleContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const CertDescriptionTitleIconContainer = styled.img`
    width: 2.25rem;
    height: 2.25rem;
    object-fit: contain;
`;

const CertDescriptionTitleTextContainer = styled.h4`
    ${Fonts.headline4};
    font-size: 1.75rem;
    font-weight: ${JrcFontWeights.bold};
    color: ${Palette.othersDeepBlue};
`;

const CertDescriptionSubtitleContainer = styled.h6`
    ${Fonts.headline6};
    font-size: 1.25rem;
    color: ${Palette.black78};
`;

export const CertDescriptionView = () => {
    return (
        <CertDescriptionContainer>
            <CertDescriptionTitleContainer>
                <CertDescriptionTitleIconContainer src={PngAssets.info} />
                <CertDescriptionTitleTextContainer>
                    {S.certPage.subContentDescriptionTitle}
                </CertDescriptionTitleTextContainer>
            </CertDescriptionTitleContainer>
            <CertDescriptionSubtitleContainer>
                {S.certPage.subContentDescriptionSubtitle}
            </CertDescriptionSubtitleContainer>
            <JrcSizedBox height={31} />
        </CertDescriptionContainer>
    );
};
