import React from "react";
import styled from "styled-components";
import { S } from "../../../theme/s";
import { JrcFontWeights, JrcSizedBox } from "@jeekim-company/jeekim-react-core";
import { Fonts } from "../../../theme/fonts";

const CertTitleLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const CertTitleContainer = styled.pre`
    ${Fonts.headline4}
    font-weight: ${JrcFontWeights.bold};
    text-align: start;
`;

const CertDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const CertInlineDescriptionContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const CertDescriptionRegularContainer = styled.pre`
    ${Fonts.headline5}
    font-weight: ${JrcFontWeights.regular};
    text-align: start;
`;

const CertDescriptionBoldContainer = styled(CertDescriptionRegularContainer)`
    font-weight: ${JrcFontWeights.bold};
`;

export const CertTitleView = () => {
    return (
        <CertTitleLayoutContainer>
            <CertTitleContainer>
                {S.certPage.mainContentTitle}
            </CertTitleContainer>
            <JrcSizedBox height={22} />
            <CertDescriptionContainer>
                <CertInlineDescriptionContainer>
                    <CertDescriptionBoldContainer>
                        {S.certPage.mainContentDescriptionOne}
                    </CertDescriptionBoldContainer>
                    <CertDescriptionRegularContainer>
                        {S.certPage.mainContentDescriptionTwo}
                    </CertDescriptionRegularContainer>
                </CertInlineDescriptionContainer>
                <CertInlineDescriptionContainer>
                    <CertDescriptionRegularContainer>
                        {S.certPage.mainContentDescriptionThree}
                    </CertDescriptionRegularContainer>
                    <CertDescriptionBoldContainer>
                        {S.certPage.mainContentDescriptionFour}
                    </CertDescriptionBoldContainer>
                    <CertDescriptionRegularContainer>
                        {S.certPage.mainContentDescriptionFive}
                    </CertDescriptionRegularContainer>
                </CertInlineDescriptionContainer>
            </CertDescriptionContainer>
        </CertTitleLayoutContainer>
    );
};
