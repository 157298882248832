import { BaseRepository } from "./base_repository";

class BrowserRepository extends BaseRepository {
    isMobile(): boolean {
        let isMobile = false;
        (function (agent: string) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    agent
                )
            )
                isMobile = true;
        })(navigator.userAgent);
        return isMobile;
    }

    isMacOs(): boolean {
        const agent = navigator.userAgent;

        return agent.indexOf("Mac OS X") !== -1;
    }
}

export const browserRepository = new BrowserRepository();
