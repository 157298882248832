export class CertConstants {
    static userInputMaxLength: number = 10;

    static getCertOidLabel(oid: string) {
        //금융결제원(Yessign tmpPolicy =KFTC)
        if (oid === "1.2.410.200005.1.1.1") return "금융 개인";
        else if (oid === "1.2.410.200005.1.1.2") return "금융 기업";
        else if (oid === "1.2.410.200005.1.1.4") return "은행/보험용";
        else if (oid === "1.2.410.200005.1.1.5") return "범용 기업";
        else if (oid === "1.2.410.200005.1.1.6") return "용도제한용";
        else if (oid === "1.2.410.200005.1.1.6.1") return "기업뱅킹용";
        else if (oid === "1.2.410.200005.1.1.6.2") return "신용카드용";
        else if (oid === "1.2.410.200005.1.1.6.3") return "조달청원클릭용";
        //행정기관- 2006.6.15
        else if (oid === "1.2.410.100001.2.2.1") return "공무원용";
        else if (oid === "1.2.410.100001.2.1.2") return "컴퓨터용";
        else if (oid === "1.2.410.100001.2.1.1") return "전자관인용";
        //공공기관
        else if (oid === "1.2.410.100001.2.2.2") return "개인용";
        else if (oid === "1.2.410.100001.2.1.4") return "전자관인용";
        else if (oid === "1.2.410.100001.2.1.5") return "컴퓨터용";
        else if (oid === "1.2.410.100001.2.1.6") return "전자특수관인용";
        //대법원
        else if (oid === "1.2.410.100001.2.2.1") return "개인용";
        else if (oid === "1.2.410.100001.2.1.1") return "전자관인용";
        else if (oid === "1.2.410.100001.2.1.2") return "컴퓨터용";
        else if (oid === "1.2.410.100001.2.1.3") return "전자특수관인용";
        //한국 증권전산(코스콤 tmpPolicy = SignKorea)
        else if (oid === "1.2.410.200004.5.1.1.1") return "스페셜 개인";
        else if (oid === "1.2.410.200004.5.1.1.2") return "스페셜 개인서버";
        else if (oid === "1.2.410.200004.5.1.1.3") return "스페셜 법인";
        else if (oid === "1.2.410.200004.5.1.1.4") return "스페셜 서버";
        else if (oid === "1.2.410.200004.5.1.1.5") return "범용 개인";
        else if (oid === "1.2.410.200004.5.1.1.6") return "범용 개인서버";
        else if (oid === "1.2.410.200004.5.1.1.7") return "범용 법인";
        else if (oid === "1.2.410.200004.5.1.1.8") return "범용 서버";
        else if (oid === "1.2.410.200004.5.1.1.9") return "증권/보험용";
        else if (oid === "1.2.410.200004.5.1.1.9.2") return "신용카드용";
        else if (oid === "1.2.410.200004.5.1.1.10") return "골드 개인서버";
        else if (oid === "1.2.410.200004.5.1.1.11") return "실버 개인";
        else if (oid === "1.2.410.200004.5.1.1.12") return "실버 법인";
        //한국무역정보통신(TradeSign)
        else if (oid === "1.2.410.200012.1.1.1") return "전자거래 서명용(개인)";
        else if (oid === "1.2.410.200012.1.1.2") return "전자거래 암호용(개인)";
        else if (oid === "1.2.410.200012.1.1.3") return "전자거래 서명용(법인)";
        else if (oid === "1.2.410.200012.1.1.4") return "전자거래 암호용(법인)";
        else if (oid === "1.2.410.200012.1.1.5") return "전자거래 서명용(서버)";
        else if (oid === "1.2.410.200012.1.1.6") return "전자거래 암호용(서버)";
        else if (oid === "1.2.410.200012.1.1.7") return "전자무역 서명용(개인)";
        else if (oid === "1.2.410.200012.1.1.8") return "전자무역 암호용(개인)";
        else if (oid === "1.2.410.200012.1.1.9") return "전자무역 서명용(법인)";
        else if (oid === "1.2.410.200012.1.1.10") return "전자무역 암호용(법인";
        else if (oid === "1.2.410.200012.1.1.11")
            return "전자무역 서명용(서버)";
        else if (oid === "1.2.410.200012.1.1.12")
            return "전자무역 암호용(서버)";
        else if (oid === "1.2.410.200012.1.1.13") return "특수목적(개인)";
        else if (oid === "1.2.410.200012.1.1.15") return "특수목적(법인)";
        else if (oid === "1.2.410.200012.1.1.17") return "특수목적(서버)";
        else if (oid === "1.2.410.200012.1.1.101") return "은행/보험용";
        else if (oid === "1.2.410.200012.1.1.103") return "증권/보험용";
        else if (oid === "1.2.410.200012.1.1.105") return "신용카드용";
        //한국전자인증(CrossCert)
        else if (oid === "1.2.410.200004.5.4.1.1") return "범용(개인)";
        else if (oid === "1.2.410.200004.5.4.1.2") return "범용(법인)";
        else if (oid === "1.2.410.200004.5.4.1.3") return "범용(서버)";
        else if (oid === "1.2.410.200004.5.4.1.4") return "특수목적용(개인)";
        else if (oid === "1.2.410.200004.5.4.1.5") return "특수목적용(법인)";
        else if (oid === "1.2.410.200004.5.4.1.101") return "은행/보험용";
        else if (oid === "1.2.410.200004.5.4.1.102") return "증권거래용";
        else if (oid === "1.2.410.200004.5.4.1.103") return "신용카드용";
        else if (oid === "1.2.410.200004.5.4.1.104") return "전자민원용";
        //한국정보인증(SignGate)
        else if (oid === "1.2.410.200004.5.2.1.1") return "1등급인증서(법인)";
        else if (oid === "1.2.410.200004.5.2.1.2") return "1등급인증서(개인)";
        else if (oid === "1.2.410.200004.5.2.1.3") return "특별등급(전자입찰)";
        else if (oid === "1.2.410.200004.5.2.1.4") return "1등급인증서(서버)";
        else if (oid === "1.2.410.200004.5.2.1.5") return "특별등급 법인";
        else if (oid === "1.2.410.200004.5.2.1.7.1") return "은행/보험용";
        else if (oid === "1.2.410.200004.5.2.1.7.2") return "증권/보험용";
        else if (oid === "1.2.410.200004.5.2.1.7.3") return "신용카드용";
        //한국전산원(NCA)
        else if (oid === "1.2.410.200004.5.3.1.1") return "1등급(기관/단체)";
        else if (oid === "1.2.410.200004.5.3.1.2") return "1등급(법인)";
        else if (oid === "1.2.410.200004.5.3.1.3") return "1등급(서버)";
        else if (oid === "1.2.410.200004.5.3.1.5")
            return "특수목적용(기관/단체)";
        else if (oid === "1.2.410.200004.5.3.1.6") return "특수목적용(법인)";
        else if (oid === "1.2.410.200004.5.3.1.7") return "특수목적용(서버)";
        else if (oid === "1.2.410.200004.5.3.1.8") return "특수목적용(개인)";
        else if (oid === "1.2.410.200004.5.3.1.9") return "1등급(개인)";
        //전자세금계산서용
        else if (oid === "1.2.410.200005.1.1.6.8") return "전자세금용";
        else if (oid === "1.2.410.200004.5.2.1.6.257") return "e세로전용인증서";
        else if (oid === "1.2.410.200004.5.4.2.80") return "e세로전용인증서";
        // epki
        else if (oid === "1.2.410.100001.5.3.1.3") return "일반인증서";
        else if (oid === "1.2.410.100001.5.3.1.1") return "전자관인";
        else return "";
    }
}
