import axios, { AxiosError } from "axios";
import { EnvironmentConstants } from "../../constants/environment";
import { DomainError } from "../../domain/model/common/domain_error";
import { ErrorCode } from "../../domain/model/common/error_code";
import { ServerSentError } from "../../domain/model/common/server_sent_error";

enum LogType {
    request = "[REQUEST]",
    response = "[RESPONSE]",
    error = "[ERROR]",
}

const log = ({ logType, message }: { logType: LogType; message: any }) => {
    if (!EnvironmentConstants.isDevelopment) return;

    const logMessage = `${logType}: ${JSON.stringify(message, null, 2)}`;

    console.log(logMessage);
};

function createHttpClient() {
    const client = axios.create({
        baseURL: EnvironmentConstants.apiUrl,
        headers: {
            Device: "Web",
            "Content-Type": "application/json; charset=UTF-8",
            accept: "application/json",
        },
        withCredentials: true,
        timeout: 60 * 1000,
    });

    client.interceptors.request.use((request) => {
        log({ logType: LogType.request, message: request.data });
        return request;
    });

    client.interceptors.response.use(
        (response) => {
            log({ logType: LogType.response, message: response.data });
            return response;
        },
        (error) => {
            log({
                logType: LogType.error,
                message: error.response.data ?? error.response,
            });

            const errorData = error.response.data;
            if (errorData && errorData.code && errorData.message) {
                if (errorData.code === ErrorCode.ServerSentError) {
                    const json = JSON.parse(errorData.message);
                    return Promise.reject(
                        new ServerSentError(json.title, json.message)
                    );
                }

                return Promise.reject(
                    new DomainError(errorData.code, errorData.message)
                );
            }

            if (
                error instanceof AxiosError &&
                error.code === AxiosError.ERR_CANCELED
            ) {
                return Promise.reject(
                    new DomainError(
                        ErrorCode.Cancelled,
                        "Error cancelled by user"
                    )
                );
            }

            if (error instanceof AxiosError) {
                return Promise.reject(
                    new DomainError(ErrorCode.NetworkError, "Network error")
                );
            }

            return Promise.reject(DomainError.unknown());
        }
    );

    return client;
}

function createLocalClient() {
    const client = axios.create({
        baseURL: "https://127.0.0.1:16566/",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            accept: "application/json",
        },
    });

    return client;
}

const httpClient = createHttpClient();
const localClient = createLocalClient();

export class BaseRepository {
    httpClient = httpClient;
    localClient = localClient;
}
