const isDevelopment = () => {
    const value = process.env.REACT_APP_IS_DEVELOPMENT;
    return value === "true";
};

const apiUrl = () => {
    if (isDevelopment()) {
        return "https://dev-crj.jeekim-server.com/";
    }

    return "https://crj.jeekim-server.com/";
};

const imageBannerUrl = () => {
    return process.env.REACT_APP_JEEKIM_BANNER_URL;
};

export const EnvironmentConstants = {
    isDevelopment: isDevelopment(),
    apiUrl: apiUrl(),
    imageBannerUrl: imageBannerUrl(),
};
