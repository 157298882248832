export const S = {
    errorPage: {
        errorOccured: "에러가 발생했습니다",
    },

    common: {
        unknownErrorMessage: "알 수 없는 에러가 발생했습니다.",
    },

    certPage: {
        installationRequiredMessage:
            "공인인증서 모듈 설치가 필요합니다. 파이어폭스의 경우 설치 완료 후 브라우저를 재실행해주시기 바랍니다.",
        mainContentTitle: "PC에서 지킴 앱으로\n공인인증서를 내보냅니다.",
        mainContentDescriptionOne: "사업자 번호 10자리",
        mainContentDescriptionTwo: "를 입력하신 후",
        mainContentDescriptionThree: "내보내실 ",
        mainContentDescriptionFour: "사업자용",
        mainContentDescriptionFive: " 공인인증서의 비밀번호를 입력해주세요.",
        registerButtonTitle: "공인인증서 등록",
        subContentDescriptionTitle: "공인인증서를 왜 등록해야 하나요?",
        subContentDescriptionSubtitle:
            "홈택스와 금융기관에 분산되어 있는 세금 자료를 수집하기 위해 고객님의 공인인증서 등록이 필요해요",
        registerSuccessMessage: "등록완료",
        certRegistrationSuccessMessage: "공인인증서 등록이 완료됐어요!",
        mobileUnsupportedErrorMessage: "현재 모바일은 지원하지 않습니다.",
        macOsNotSupportedMessage: "현재 Mac OS는 지원하지 않습니다.",
        invalidBusinessRegistrationNumberErrorMessage:
            "유효하지 않은 사업자번호입니다. 다시 한번 확인해주세요.",
        certRegistrationErrorMessage:
            "공인인증 등록 중에 에러가 발생했습니다. 문제가 계속 되면 고객센터로 문의해주세요.",
    },
};
