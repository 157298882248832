import { createBrowserRouter } from "react-router-dom";
import { appRouteMapping } from "./app_route_mapping";

const router = createBrowserRouter(appRouteMapping);

export const AppRouter = {
    ...router,
    popIfAvailable() {
        if (router.state.location.key === "default") return;

        router.navigate(-1);
    },
};
