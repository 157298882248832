import { atom } from "recoil";

interface CertState {
    userInput: string;
    registerSuccess: boolean;
}

export const certState = atom<CertState>({
    key: "certState",
    default: {
        userInput: "",
        registerSuccess: false,
    },
});
