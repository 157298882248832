import React from "react";
import styled from "styled-components";

const SpacerContainer = styled.div`
    flex-grow: 21234234;
    background-color: aliceblue;
`;

export const Spacer = () => {
    return <SpacerContainer />;
};
