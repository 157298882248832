import React from "react";
import { createGlobalStyle } from "styled-components";
import { PngAssets } from "./assets";
import {
    JrcAnimationTheme,
    JrcAppBarTheme,
    JrcErrorTheme,
    JrcFont,
    JrcFontConfig,
    JrcFontTheme,
    JrcFontWeights,
    JrcFonts,
    JrcPageTheme,
} from "@jeekim-company/jeekim-react-core";
import { Palette } from "./palette";

const GlobalThemeContainer = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    };

    body {
        overflow-y: scroll;
    };
`;

export const GlobalTheme = () => {
    return <GlobalThemeContainer />;
};

export const appBarTheme: JrcAppBarTheme = {
    appBarHeightInRem: "3.25rem",
};

export const pageTheme: JrcPageTheme = {
    maxPageWidthInRem: undefined,
};

export const animationTheme: JrcAnimationTheme = {
    defaultAnimationSpeed: 300,
};

export const errorTheme: JrcErrorTheme = {
    errorViewIcon: PngAssets.warning,
    errorViewMessage: "에러가 발생했습니다",
};

export const fontTheme: JrcFontTheme = {
    fonts: new JrcFonts({
        baseConfig: new JrcFontConfig({
            baseTextColor: Palette.black100,
            baseLineHeight: 1.5,
            baseFontFamily: "Spoqa Han Sans Neo",
        }),
        headline1: new JrcFont("6rem", "-0.0938rem", JrcFontWeights.light),
        headline2: new JrcFont("3.75rem", "-0.0313rem", JrcFontWeights.light),
        headline3: new JrcFont("3rem", "0rem", JrcFontWeights.regular),
        headline4: new JrcFont("2.125rem", "0.0156rem", JrcFontWeights.regular),
        headline5: new JrcFont("1.5rem", "0rem", JrcFontWeights.medium),
        headline6: new JrcFont("1.25rem", "0.0094rem", JrcFontWeights.regular),
        subtitle1: new JrcFont("1rem", "0.0094rem", JrcFontWeights.regular),
        subtitle2: new JrcFont("0.875rem", "0.0063rem", JrcFontWeights.medium),
        body1: new JrcFont("1rem", "0.0313rem", JrcFontWeights.regular),
        body2: new JrcFont("0.875rem", "0.0156rem", JrcFontWeights.regular),
        caption: new JrcFont("0.75rem", "0.025rem", JrcFontWeights.regular),
        button: new JrcFont("0.875rem", "0.0781rem", JrcFontWeights.medium),
        overline: new JrcFont("0.625rem", "0.0938rem", JrcFontWeights.regular),
    }),
};
