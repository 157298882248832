import { JrcControllerState } from "@jeekim-company/jeekim-react-core";

export class CertControllerState extends JrcControllerState {}

export class CertRegisterSuccess extends CertControllerState {}

export class CertMobileUnsupportedError extends CertControllerState {}

export class CertMacOsUnsupportedError extends CertControllerState {}

export class CertNxModuleUninstalledError extends CertControllerState {
    installUrl: string;

    constructor(installUrl: string) {
        super();
        this.installUrl = installUrl;
    }
}

export class CertInvalidBusinessRegistrationNumberError extends CertControllerState {}

export class CertRegisterError extends CertControllerState {}

export class CertServerSentError extends CertControllerState {
    title?: string;
    message?: string;

    constructor(title?: string, message?: string) {
        super();
        this.title = title;
        this.message = message;
    }
}

export class CertUnknownError extends CertControllerState {}
