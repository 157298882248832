import { ErrorCode } from "./error_code";

export class DomainError extends Error {
    errorCode: ErrorCode;

    constructor(errorCode: ErrorCode, message?: string) {
        super(message);
        this.errorCode = errorCode;
    }

    static unknown() {
        return new DomainError(ErrorCode.Unknown, "Unknown error");
    }
}
