import { ReactComponent as Done } from "../../assets/svgs/ic_24_done.svg";
import { ReactComponent as KeyboardArrowRight } from "../../assets/svgs/ic_24_keyboard_arrow_right.svg";

export class PngAssets {
    static certGraphics = require("../../assets/images/img_cert_graphics@3x.png");
    static checkBlue = require("../../assets/images/ic_24_check_blue@3x.png");
    static checkCircleBlue = require("../../assets/images/ic_24_check_circle_blue@3x.png");
    static checkCircleGrey = require("../../assets/images/ic_24_check_circle_grey@3x.png");
    static info = require("../../assets/images/ic_24_info@3x.png");
    static warning = require("../../assets/images/ic_60_warning@3x.png");
}

export class SvgAssets {
    static Done = Done;
    static KeyboardArrowRight = KeyboardArrowRight;
}
