import { selector } from "recoil";
import { certState } from "../atoms/state";

export const certUserInputSelector = selector({
    key: "certUserInputSelector",
    get: ({ get }) => {
        const state = get(certState);
        return state.userInput;
    },
});
