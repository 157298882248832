import React from "react";
import styled from "styled-components";
import { CertTitleView } from "./cert_title_view";
import { CertInputView } from "./cert_input_view";
import { CertRegisterButtonView } from "./cert_register_button_view";
import { Spacer } from "../../../components/spacer";
import { CertDescriptionView } from "./cert_description_view";
import { CertGraphicsView } from "./cert_graphics_view";
import { JrcSizedBox } from "@jeekim-company/jeekim-react-core";

interface CertConstraintsContainerProps {
    $margin: number;
    $width: number;
    $height: number;
}

const CertConstraintsContainer = styled.div<CertConstraintsContainerProps>`
    margin: ${(props) => `${props.$margin}px`};
    min-width: ${(props) =>
        `calc(${props.$width}rem + ${props.$margin * 2}px)`};
    min-height: ${(props) =>
        `calc(${props.$height}rem + ${props.$margin * 2}px)`};
`;

const CertBackgroundContainer = styled(CertConstraintsContainer)`
    width: 100%;
    height: 100%;
    min-height: 100lvh;
    min-height: 100dvh; // fallback
    min-height: 100vh; // fallback
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CertContainer = styled(
    CertConstraintsContainer
)<CertConstraintsContainerProps>`
    width: ${(props) => `${props.$width}rem`};
    height: ${(props) => `${props.$height}rem`};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const CertMainContentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const CertView = () => {
    const margin = 20;
    const width = 81.5;
    const height = 40.25;

    return (
        <CertBackgroundContainer
            $margin={margin}
            $width={width}
            $height={height}
        >
            <CertContainer $margin={margin} $width={width} $height={height}>
                <CertMainContentContainer>
                    <CertTitleView />
                    <JrcSizedBox height={60} />
                    <CertInputView />
                    <JrcSizedBox height={60} />
                    <CertRegisterButtonView />
                    <Spacer />
                    <CertDescriptionView />
                </CertMainContentContainer>
                <Spacer />
                <CertGraphicsView />
            </CertContainer>
        </CertBackgroundContainer>
    );
};
