import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Palette } from "../../../theme/palette";
import { useRecoilValue } from "recoil";
import { certUserInputSelector } from "../../../\bstates/cert/selectors/cert_user_input_selector";
import { certRegisterSuccessSelector } from "../../../\bstates/cert/selectors/cert_register_success_selector";
import { CertRegisterSuccessLabelView } from "./cert_register_success_label_view";
import {
    JrcFontWeights,
    JrcSizedBox,
    JrcTransitioner,
    jrcFadeScaleInOutTransition,
} from "@jeekim-company/jeekim-react-core";
import { Fonts } from "../../../theme/fonts";

const CertInputViewContainer = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

export const CertInputView = () => {
    const registerSuccess = useRecoilValue(certRegisterSuccessSelector);

    return (
        <CertInputViewContainer>
            <CertInputNumberView inputPosition={0} />
            <CertInputNumberView inputPosition={1} />
            <CertInputNumberView inputPosition={2} />
            <JrcSizedBox width={16} />
            <CertInputNumberView inputPosition={3} />
            <CertInputNumberView inputPosition={4} />
            <JrcSizedBox width={16} />
            <CertInputNumberView inputPosition={5} />
            <CertInputNumberView inputPosition={6} />
            <CertInputNumberView inputPosition={7} />
            <CertInputNumberView inputPosition={8} />
            <CertInputNumberView inputPosition={9} />
            <CertRegisterSuccessLabelView visible={registerSuccess} />
        </CertInputViewContainer>
    );
};

const CertInputNumberViewContainer = styled.li`
    width: 4rem;
    height: 5rem;
    border-radius: 1rem;
    background-color: ${Palette.black5Bg};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NumberView = styled.label`
    ${Fonts.headline4}
    font-size: 2.125rem;
    font-weight: ${JrcFontWeights.bold};
    color: ${Palette.black100};

    ${jrcFadeScaleInOutTransition};
`;

const CertInputNumberView = ({ inputPosition }: { inputPosition: number }) => {
    const ref = useRef(null);
    const userInput = useRecoilValue(certUserInputSelector);
    const displayValueRef = useRef("");
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (userInput.length > inputPosition) {
            displayValueRef.current = userInput[inputPosition];
            setVisible(true);
            return;
        }

        setVisible(false);
    }, [userInput, inputPosition]);

    return (
        <CertInputNumberViewContainer>
            <JrcTransitioner
                componentRef={ref}
                visible={visible}
                className={`cert-input-number-${inputPosition}`}
                animateOnMount={false}
                transitionOut={false}
                child={
                    <NumberView ref={ref}>{displayValueRef.current}</NumberView>
                }
            />
        </CertInputNumberViewContainer>
    );
};
