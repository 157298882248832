import { JrcController } from "@jeekim-company/jeekim-react-core";
import { authRepository } from "../../../data/repository/auth_repository";
import { browserRepository } from "../../../data/repository/browser_repository";
import { certRepository } from "../../../data/repository/cert_repository";
import { DomainError } from "../../../domain/model/common/domain_error";
import { ErrorCode } from "../../../domain/model/common/error_code";
import {
    CertControllerState,
    CertMacOsUnsupportedError,
    CertMobileUnsupportedError,
    CertNxModuleUninstalledError,
    CertRegisterSuccess,
    CertInvalidBusinessRegistrationNumberError,
    CertUnknownError,
    CertRegisterError,
    CertServerSentError,
} from "./cert_controller_state";
import { ServerSentError } from "../../../domain/model/common/server_sent_error";

class CertController extends JrcController<CertControllerState> {
    async onCertPageLaunched(): Promise<void> {
        try {
            const isMobile = browserRepository.isMobile();

            if (isMobile) {
                this.updateState(new CertMobileUnsupportedError());
                return;
            }

            const isMacOs = browserRepository.isMacOs();

            if (isMacOs) {
                this.updateState(new CertMacOsUnsupportedError());
                return;
            }

            const installed = await certRepository.getInstallationStatus();

            if (!installed) {
                const installUrl = certRepository.getInstallUrl(isMacOs);
                this.updateState(new CertNxModuleUninstalledError(installUrl));
                return;
            }
        } catch (e) {
            if (!(e instanceof DomainError)) return;

            switch (e.errorCode) {
                default:
                    this.updateState(new CertUnknownError());
                    break;
            }
        }
    }

    async onCertRegisterRequested(
        businessRegistrationNumber: string
    ): Promise<void> {
        try {
            const isMobile = browserRepository.isMobile();

            if (isMobile) {
                this.updateState(new CertMobileUnsupportedError());
                return;
            }

            const isMacOs = browserRepository.isMacOs();

            if (isMacOs) {
                this.updateState(new CertMacOsUnsupportedError());
                return;
            }

            const installed = await certRepository.getInstallationStatus();

            if (!installed) {
                const installUrl = certRepository.getInstallUrl(isMacOs);
                this.updateState(new CertNxModuleUninstalledError(installUrl));
                return;
            }

            await authRepository.isAuthorized(businessRegistrationNumber);

            const certResult = await certRepository.getCertResult();

            const certValidationResult =
                await certRepository.getCertValidationResult(certResult);

            await certRepository.registerCert({
                businessRegistrationNumber,
                certResult,
                certValidationResult,
            });

            this.updateState(new CertRegisterSuccess());
        } catch (e) {
            if (e instanceof ServerSentError) {
                this.updateState(new CertServerSentError(e.title, e.message));
                return;
            }
            if (!(e instanceof DomainError)) return;

            switch (e.errorCode) {
                case ErrorCode.CertListWindowClosed:
                    // no-op
                    break;

                case ErrorCode.InvalidBusinessRegistrationNumber:
                    this.updateState(
                        new CertInvalidBusinessRegistrationNumberError()
                    );
                    break;

                case ErrorCode.CannotSyncEdi:
                    this.updateState(new CertRegisterError());
                    break;

                default:
                    this.updateState(new CertUnknownError());
                    break;
            }
        }
    }
}

export const certController = new CertController();
