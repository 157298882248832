import React from "react";
import styled from "styled-components";
import { PngAssets } from "../../../theme/assets";

const CertGraphicsViewContainer = styled.img`
    width: auto;
    max-height: 100%;
    object-fit: contain;
`;

export const CertGraphicsView = () => {
    return <CertGraphicsViewContainer src={PngAssets.certGraphics} />;
};
