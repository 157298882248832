import { fontTheme } from "./theme";

export class Fonts {
    static get headline1() {
        return fontTheme.fonts.headline1;
    }

    static get headline2() {
        return fontTheme.fonts.headline2;
    }

    static get headline3() {
        return fontTheme.fonts.headline3;
    }

    static get headline4() {
        return fontTheme.fonts.headline4;
    }

    static get headline5() {
        return fontTheme.fonts.headline5;
    }

    static get headline6() {
        return fontTheme.fonts.headline6;
    }

    static get subtitle1() {
        return fontTheme.fonts.subtitle1;
    }

    static get subtitle2() {
        return fontTheme.fonts.subtitle2;
    }

    static get body1() {
        return fontTheme.fonts.body1;
    }

    static get body2() {
        return fontTheme.fonts.body2;
    }

    static get caption() {
        return fontTheme.fonts.caption;
    }

    static get button() {
        return fontTheme.fonts.button;
    }

    static get overline() {
        return fontTheme.fonts.overline;
    }
}
