import React, { useCallback, useEffect } from "react";
import { CertView } from "./views/cert_view";
import { useSetRecoilState } from "recoil";
import { certState } from "../../\bstates/cert/atoms/state";
import { decimalOnlyValidator } from "../../utils/input_validators/\bdecimal_only_validator";
import { CertConstants } from "../../\bstates/cert/constants/cert_constants";
import { certController } from "../../controllers/cert/cert_controller";
import {
    CertMacOsUnsupportedError,
    CertMobileUnsupportedError,
    CertNxModuleUninstalledError,
    CertRegisterSuccess,
    CertInvalidBusinessRegistrationNumberError,
    CertUnknownError,
    CertRegisterError,
    CertServerSentError,
} from "../../controllers/cert/cert_controller_state";
import { S } from "../../theme/s";
import {
    JrcAppPage,
    useListenableJrcState,
} from "@jeekim-company/jeekim-react-core";
import { showUnknownErrorAlert } from "../../utils/functions/show_unknown_error_alert";

export const CertPage = () => {
    const setState = useSetRecoilState(certState);

    const isDeleteKey = useCallback((key: string) => {
        return key === "Backspace" || key === "Delete";
    }, []);

    useListenableJrcState(certController, (state) => {
        if (state instanceof CertRegisterSuccess) {
            alert(S.certPage.certRegistrationSuccessMessage);
            setState((prev) => {
                return {
                    ...prev,
                    registerSuccess: true,
                };
            });
        }

        if (state instanceof CertMobileUnsupportedError) {
            alert(S.certPage.mobileUnsupportedErrorMessage);
        }

        if (state instanceof CertMacOsUnsupportedError) {
            alert(S.certPage.macOsNotSupportedMessage);
        }

        if (state instanceof CertNxModuleUninstalledError) {
            const installConfirmed = window.confirm(
                S.certPage.installationRequiredMessage
            );

            if (!installConfirmed) return;

            let iframe = document.getElementById(
                "ifrFile"
            ) as HTMLIFrameElement;
            if (!iframe) {
                iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.id = "ifrFile";
                document.body.appendChild(iframe);
            }
            iframe.src = state.installUrl;
        }

        if (state instanceof CertInvalidBusinessRegistrationNumberError) {
            alert(S.certPage.invalidBusinessRegistrationNumberErrorMessage);
        }

        if (state instanceof CertRegisterError) {
            alert(S.certPage.certRegistrationErrorMessage);
        }

        if (state instanceof CertServerSentError) {
            alert(
                (state.title ?? "") +
                    "\n" +
                    (state.message ?? S.common.unknownErrorMessage)
            );
        }

        if (state instanceof CertUnknownError) {
            showUnknownErrorAlert();
        }
    });

    useEffect(() => {
        certController.onCertPageLaunched();
    }, []);

    useEffect(() => {
        const onKeyPressed = (event: KeyboardEvent) => {
            if (!decimalOnlyValidator(event.key) && !isDeleteKey(event.key)) {
                return;
            }

            setState((prev) => {
                if (isDeleteKey(event.key)) {
                    return {
                        ...prev,
                        userInput: prev.userInput.slice(0, -1),
                    };
                }

                if (prev.userInput.length >= CertConstants.userInputMaxLength) {
                    return prev;
                }

                return {
                    ...prev,
                    userInput: prev.userInput + event.key,
                };
            });
        };

        document.addEventListener("keyup", onKeyPressed, false);

        return () => document.removeEventListener("keyup", onKeyPressed, false);
    }, [setState, isDeleteKey]);

    return (
        <JrcAppPage>
            <CertView />
        </JrcAppPage>
    );
};
