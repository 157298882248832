import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Palette } from "../theme/palette";
import { SvgAssets } from "../theme/assets";
import {
    JrcFontWeights,
    JrcRippleButtonContainer,
    JrcRippleButtonContainerProps,
    JrcTransitioner,
    jrcFadeInOutTransition,
    useJrcRippleButton,
} from "@jeekim-company/jeekim-react-core";
import { Fonts } from "../theme/fonts";

const loadingAnimation = keyframes`
    0% {
         box-shadow: 0em -2.6em 0em 0em #ffffff,
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
            1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
            1.75em 1.75em 0 0em #ffffff,
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
            0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
            -2.6em 0em 0 0em #ffffff,
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
            -1.8em -1.8em 0 0em #ffffff;
    }
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff,
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
`;

const PrimaryButtonContainer = styled(JrcRippleButtonContainer)<
    {
        $loading: boolean;
    } & JrcRippleButtonContainerProps
>`
    min-width: 21.25rem;
    min-height: 5.25rem;
    cursor: ${(props) => (props.$disabled ? "auto" : "pointer")};
    display: flex;
    border: none;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    ${Fonts.button};
    position: relative;
    transition: background-color 0.3s ease-in-out;
`;

const LoaderAnimationContainer = styled.div`
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.3125rem;
    border-radius: 50%;
    animation: ${loadingAnimation} 1.1s infinite ease;
    position: absolute;

    ${jrcFadeInOutTransition};
`;

const ButtonTitleContainer = styled.span<{
    $disabled: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    position: absolute;
    ${Fonts.headline5}
    font-size: 1.5rem;
    font-weight: ${(props) =>
        props.$disabled ? JrcFontWeights.regular : JrcFontWeights.medium};
    color: ${(props) => (props.$disabled ? Palette.black20 : Palette.white100)};
    transition: font-weight 0.3s ease-in-out, color 0.3s ease-in-out;

    ${jrcFadeInOutTransition};
`;

const ButtonIconContainer = styled(SvgAssets.KeyboardArrowRight)<{
    $disabled: boolean;
}>`
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${Palette.transparent};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    fill: ${(props) => (props.$disabled ? Palette.black20 : Palette.white100)};
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out,
        fill 0.3s ease-in-out;
`;

export const PrimaryButton = ({
    disabled = false,
    loading = false,
    onClick,
    title,
}: {
    disabled?: boolean;
    loading?: boolean;
    onClick: () => void;
    title: string;
}) => {
    const buttonRef = useRef(null);
    const loaderRef = useRef(null);
    const titleRef = useRef(null);

    useJrcRippleButton({
        buttonRef: buttonRef,
        splashColor: Palette.lightSplashColor,
    });

    return (
        <PrimaryButtonContainer
            ref={buttonRef}
            disabled={disabled || loading}
            $disabled={disabled}
            $loading={loading}
            $backgroundColor={
                disabled ? Palette.disabledGrey : Palette.primary100
            }
            $hoverColor={Palette.primaryHover}
            onClick={onClick}
        >
            <JrcTransitioner
                componentRef={loaderRef}
                visible={loading}
                className={"primary-button-loader"}
                child={<LoaderAnimationContainer ref={loaderRef} />}
            />
            <JrcTransitioner
                componentRef={titleRef}
                visible={!loading}
                className={"primary-button-title"}
                child={
                    <ButtonTitleContainer ref={titleRef} $disabled={disabled}>
                        {title && title}
                        <ButtonIconContainer $disabled={disabled} />
                    </ButtonTitleContainer>
                }
            />
        </PrimaryButtonContainer>
    );
};
